globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"VuLverbaQdkbhBcp9MzK4"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { HttpError, NetworkError } from '@hubble/request';
import * as Sentry from '@sentry/nextjs';

import config from 'site-react/config';

Sentry.init({
  beforeSend: (event, hint) => {
    const error = hint.originalException;

    // If it's an HTTP error or a network error, ignore it. We get these a lot,
    // and usually they aren't actionable. Ignoring them here means they don't
    // count against our Sentry usage (and we get these enough that they were
    // regularly pushing us well over our monthly quota)
    if (
      error &&
      (error instanceof HttpError || error instanceof NetworkError)
    ) {
      // Returning `null` discards the event.
      // Source: https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/#using-platformidentifier-namebefore-send-
      return null;
    }

    return event;
  },
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i, // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i, // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i, // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i, // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  dsn: config.SENTRY_DSN,
  environment: config.ENV,
  // Browsers can produce a lot of errors that have nothing to do with our
  // code. Sentry suggests using this community-built list of ignore rules,
  // to avoid getting alerted for browser extension issues that aren't our
  // fault.
  //
  // Source: https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/
  ignoreErrors: [
    // CUSTOM FOR HUBBLE
    'Failed to fetch', // Fetch failures. Usually ad blockers or network issues.
    'Load failed', // As above: simple browser network failure. Ignore.
    // Random plugins/extensions
    'top.GLOBALS', // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose', // Facebook borked
    'fb_xd_fragment', // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived', // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    '<unknown>',
  ],
  integrations: [Sentry.replayIntegration()],
  replaysOnErrorSampleRate: 1.0, // Always send session replays on errors
  replaysSessionSampleRate: 0, // 0% sample rate—don't send session replays unless there's an error
});
