import cn from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'site-react/components/form';
import Drawer from 'site-react/components/page/Drawer';

import styles from './BookViewingButton.module.css';
import ViewingBasket from '../ViewingBasket';

export default function BookViewingButton({
  advisor,
  likedRecommendationListItems,
  setRecommendationList,
  token,
}) {
  return (
    <Drawer
      label="Book viewings"
      renderTrigger={({ openDrawer }) => {
        return (
          <Button
            aria-label="Book viewings"
            disabled={likedRecommendationListItems.length === 0}
            isCompact
            name="Book viewings"
            onClick={openDrawer}
            type="button"
          >
            Book viewings&nbsp;
            <span
              className={cn(styles['BookViewingButton-totalIcon'], {
                [styles['isNone']]: likedRecommendationListItems.length === 0,
              })}
            >
              {likedRecommendationListItems.length}
            </span>
          </Button>
        );
      }}
    >
      <ViewingBasket
        advisor={advisor}
        likedRecommendationListItems={likedRecommendationListItems}
        setRecommendationList={setRecommendationList}
        token={token}
      />
    </Drawer>
  );
}

BookViewingButton.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),

  likedRecommendationListItems: PropTypes.arrayOf(
    PropTypes.shape({
      advisorNotes: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      customerNotes: PropTypes.string,
      id: PropTypes.number.isRequired,
      isPartTime: PropTypes.bool,
      likedAt: PropTypes.string,
      pricePlanId: PropTypes.number.isRequired,
      recommendationList: PropTypes.number.isRequired,
      rejectedAt: PropTypes.string,
      status: PropTypes.oneOf([
        'liked',
        'pending',
        'viewing requested',
        'rejected',
      ]),
      updatedAt: PropTypes.string,
      viewingRequestedAt: PropTypes.string,
    }),
  ),

  setRecommendationList: PropTypes.func.isRequired,

  token: PropTypes.string.isRequired,
};
